import React from 'react'
import Layout from '../components/layout-v2';


const Page = () => (

    <Layout>
        <section className="section-home">

            <div id="root">
                <div className="ae af">


                    <main id="main">
                        <div
                            className="wcb3-billboard-cta b5 b6 b7 b8 b9 ba bb ae bx be jg bg bh bi bj c0 bl ji bn c2 bp jk br">
                            <div className="bj bs bt">
                                <h1>Amenities & Terms</h1>
                                <p>You can use our Quotebot filter to search and book the ideal vehicle of choice by
                                    matching your every request depending upon availability.</p>
                                <h3>Amenities</h3><br/>
                                <ul>
                                    <li><strong>Baby / Booster Seat –</strong> Must be provided for babies and children
                                        up to 12 years
                                        old. Drivers can supply one or both and some affiliates will hold and store your
                                        seats for an extra charge.
                                    </li>
                                    <li><strong>Qty - </strong>The number of vehicles, passengers, or luggage.</li>
                                    <li><strong>Seats and belts - </strong>The number of seats and seat belts available
                                        in each vehicle
                                    </li>
                                    <li><strong>Bus Types -</strong> We index all of the major bus makes and models. Not
                                        all bus types
                                        are available in every city. Use the Quotebot filter or send us an email
                                        Rate/Vehicle Request and one of our personalized service representatives will
                                        find exactly what you’re looking for.
                                    </li>
                                    <li><strong>Year, Make, Color, Amenity – </strong>All of these features can be found
                                        on our Quotebot
                                        filter. You may see a range of years available. We will do our best to get
                                        exactly what you seek or as close as possible. Book early. Rate/Vehicle Request.
                                    </li>
                                    <li><strong>Armored – </strong>A vehicle that is bullet proof or withstand small
                                        detonations and
                                        built to protect the inside occupants.
                                    </li>
                                    <li><strong>Bodyguard / Security – </strong>A person assigned to security at an
                                        extra charge
                                    </li>
                                    <li><strong>Lavatory - </strong>Restroom on board.</li>
                                    <li><strong>DVD - </strong>There are usually TV monitors both sides of the isle on
                                        board motor
                                        coaches. Wide screen TV can be found on Limo, Party Buses and some Sprinter
                                        Vans. Satellite TV are buses with special antenna and can be searched on our
                                        Quotebot amenity list.
                                    </li>
                                    <li><strong>CD - </strong>CD player.</li>
                                    <li><strong>PA - </strong>Public Address or Intercom system for touring and special
                                        announcements.
                                    </li>
                                    <li><strong>ADA - </strong>Wheelchair elevator. Many smaller buses and vans are
                                        equipped with a ramp
                                        or lift.
                                    </li>
                                    <li><strong>Alch -</strong> Alcohol permitted. The driver can be instructed to
                                        purchase (extra
                                        charges will apply) or BYOB.
                                    </li>
                                    <li><strong>OTR – </strong>Over the road buses, for long distance and overnight
                                        travel, can be chosen
                                        in the amenity choices on our Quotebot filter.
                                    </li>
                                    <li><strong>Gender – </strong>Male, Female, LGBTQ
                                    </li>
                                    <li><strong>Dress –</strong> The type of clothing the driver wears; suit and tie,
                                        dressy casual,
                                        casual, tuxedo.
                                    </li>
                                    <li><strong>Language –</strong> Drivers first, second or more language skills</li>
                                    <li><strong>Tour Guide – </strong>A person who travels with the Pax at an extra
                                        charge.
                                    </li>
                                    <li><strong>Bike Rack –</strong> A rack that can one or two bicycles at an extra
                                        charge.
                                    </li>
                                    <li><strong>Luggage Trailer – </strong>A trailer attached behind the Pax vehicle,
                                        usually a van or
                                        minibus, at an extra charge.
                                    </li>
                                    <li><strong>Luxury - </strong>The vehicle is considered a “higher class” and more
                                        expensive vehicle,
                                        in its size range.
                                    </li>

                                </ul>
                                <h3>Terms</h3>
                                <ul>
                                    <li><strong>Quotebot – </strong>BellMe’s exclusive booking engine to Search,
                                        Compare and Book any
                                        type vehicle or size.
                                    </li>
                                    <li><strong>Booking – </strong>A confirmation for service.</li>
                                    <li><strong>Pax – </strong>Abbreviation for Passengers.</li>
                                    <li><strong>Taxi – </strong>An independent driver of a licensed vehicle which uses a
                                        meter to track,
                                        the flag rate plus time and distance
                                    </li>
                                    <li><strong>Black Car –</strong>A higher end Sedan and SUV service. Many drivers
                                        wear a suit and
                                        tie. Can work independently or in a fleet.
                                    </li>
                                    <li><strong>Limo – </strong>Similar to a Black Car but with a larger vehicle,
                                        usually a stretched
                                        sedan or SUV that seats 6 or more Pax.
                                    </li>
                                    <li><strong>Gig – </strong>An independent driver that usually drives for a TNC
                                        (Technology Network
                                        Company) like Lyft / Uber. The types of vehicles can range from a compact sedan
                                        to larger mini-vans and SUVs. All vehicles must have 4 doors.
                                    </li>
                                    <li><strong>Fleet Operator – </strong>A company that offers many vehicles of the
                                        same or different
                                        type and size vehicles.
                                    </li>
                                    <li><strong>One-Way – </strong>Also known as a Pick and Drop. No return.</li>
                                    <li><strong>Round Trip – </strong>A pick up going to and from the same address /
                                        destination.
                                    </li>
                                    <li><strong>Hourly Charter – </strong>The number of hours a vehicle is booked,
                                        usually with a 2 Hr.
                                        minimum depending on the vehicle type or event.
                                    </li>
                                    <li><strong>As Directed – </strong>The vehicle is booked with a 2-Hr or more charter
                                        and drives as
                                        directed, planned or unplanned routes.
                                    </li>
                                    <li><strong>Baggage Meet – </strong>The driver will meet the Pax in the airport
                                        baggage claim area
                                        with a sign and name. Extra charge.
                                    </li>
                                    <li><strong>Wait Time – </strong>Time the driver waits either before the pickup or
                                        during any stops
                                        along to the destination. Extra charge.
                                    </li>
                                    <li><strong>Extra Stops –</strong> Additional stops that may be planned or unplanned
                                        between two
                                        destinations. Extra charge.
                                    </li>
                                    <li><strong>Early AM / Late PM / Holiday –</strong> An extra charge may apply to
                                        these times of day
                                        or occasion.
                                    </li>
                                    <li><strong>Parking – </strong>The fee charges for the vehicle to park at the
                                        airport, port, or drop
                                        off location when waiting.
                                    </li>
                                    <li><strong>Tolls – </strong>Extra charge for using the toll roads. Some affiliates
                                        charge both ways,
                                        even if dead-heading.
                                    </li>
                                    <li><strong>Clean up / Damage Fee – </strong>A minimum clean up fee of US$100.00 or
                                        any interior
                                        damage caused by the client.
                                    </li>
                                    <li><strong>Taxes / VAT – </strong>Can apply for City, State, Airport, Port, Gig,
                                        Value Added
                                        (Europe) and Workman’s Comp.
                                    </li>
                                    <li><strong>Greet Type –</strong> Driver Instructions to pick up a client. Call,
                                        Text, Park in
                                        Driveway, Ring Bell, Baggage Meet, etc.
                                    </li>
                                    <li><strong>Dead-Head – </strong>When a vehicle travels empty, to or from a pickup
                                        or drop off.
                                    </li>
                                    <li><strong>One Way, Roundtrip, or Charter – </strong>We offer you more options than
                                        any other
                                        transportation company to get you or your group to and from your destination
                                        with any type vehicle using our Quotebot to search and book with the lowest
                                        available rate.
                                    </li>
                                    <li><strong>2 - 6 Hour Charter Minimum – </strong>The number of minimum hours is
                                        based by bus size
                                        and city. For local bus service, a 2-hour min can be booked. For longer distance
                                        or as directed local bookings are usually a 5-hour minimum. OTR buses are either
                                        by mileage or day rate.
                                    </li>
                                    <li><strong>Day - </strong>Day rates are between 8 - 10 hours of local use.
                                    </li>
                                    <li><strong>Mileage - </strong>The rate per mile to any two location, either
                                        locally, in-state, or
                                        out of state. Quotes are calculated either by a minimum rate and a mileage rate
                                        whichever is greater.
                                    </li>
                                    <li><strong>Misc. –</strong> Miscellaneous charges the client is responsible to pay;
                                        usually to
                                        reimburse the driver for out-of-pocket expenses
                                    </li>
                                </ul>
                                <p>Other amenities that can be found on buses are tables, booth-like seating, small
                                    beds, shades, carpeting, satellite and catered food. Mini-buses and vans have rear
                                    luggage compartments, reclining seats, captain chairs, and cargo trailers.
                                </p>
                            </div>
                            <br/>
                        </div>
                    </main>
                </div>
            </div>
        </section>
    </Layout>
);

export default Page;
